import React, { useEffect, useState } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
// import HeadPageComponent from "../../components/layout/headpage/headpage";
import { useTranslation } from 'react-i18next';
import { Autocomplete, Button, Card, Modal, TextField } from '@mui/material';
import axios from 'axios';
import TableHistory from './components/Table';
import TablePayrent from './components/TablePayrent';
import { useParams } from 'react-router-dom';
import { useRef } from 'react';
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  pdf,
} from '@react-pdf/renderer';
import ReactToPdf from 'react-to-pdf';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useSelector } from 'react-redux';
import { getPayRentDetail, svDeleteDetailsPayment } from '../../../services/payrent.service';
import moment from 'moment';
import HistoryRentt from "./HistoryRentt";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SwalUI from '../../../components/ui/swal-ui/swal-ui';
const modalSwal = withReactContent(Swal);

function PayRenttDetails() {
  const { id } = useParams();
  const { t } = useTranslation(['dashboard-page']);
  const [loading, setLoading] = useState(true);
  const [exportedProductDetails, setExportedProductDetails] = useState([]);
  const [refreshData, setRefreshData] = useState(0);
  const [year, setYear] = useState('');
  const [showTable, setShowTable] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [dataPayRent, setDataPayRent] = useState([]);
  const [detailPayRent, setdetailPayRent] = useState([]);
  const [detailPayment, setdetailPayment] = useState([]);
  const [detailYear, setDetailYear] = useState([]);
  const uploadPath = useSelector((state) => state.app.uploadPath);
  const [openEditDate, setOpenEditDate] = useState(false);
  const [editData, setEditData] = useState();

  useEffect(() => {
    console.log(id);
    getPayRentDetail(id).then((res) => {
      console.log(res);
      setDataPayRent(res.data.detail[0]);
      setdetailPayRent(res.data.detail);
      setdetailPayment(res.data.payment);
      setDetailYear(res.data.year);
    });
  }, [id,refreshData]);

  // if (detailPayment && detailPayment.length > 0) {
  //   console.log(moment(detailPayment[0]['RentalDate']).year()); // หรือใช้ Optional Chaining: detailPayment?.[0]?.RentalDate
  //   // console.log(detailPayment);
  //   // console.log(detailPayRent);
  // } else {
  //   console.log('detailPayment is empty or undefined');
  // }

  const handleChange = (event) => {
    setYear(event.target.value);
  };

  const showTableHandler = () => {
    setShowTable(true); // เปลี่ยนค่า state เพื่อแสดงตาราง
    setShowForm(false); // ซ่อนฟอร์ม
  };

  const showFormHandler = () => {
    setShowTable(false); // ซ่อนตาราง
    setShowForm(true); // เปิดฟอร์ม
  };

  const rowsFilteredDetail = detailPayRent.filter((row) => {
    if (
      year === '' ||
      moment(row.RentalDate).year() === year
      // Filter by Year
    ) {
      return true;
    } else {
      return false;
    }
  });

  const rowsFilteredPayment = detailPayment.filter((row) => {
    if (year === '' || moment(row.RentalDate, 'DD/MM/YYYY').year() === year) {
      return true;
    } else {
      return false;
    }
  });

  const years = Array.from(new Set(detailYear.map((row) => row.year)));

  const YearSet = years.map((year, index) => (
    <MenuItem key={index} value={year}>
      {year}
    </MenuItem>
  ));

  const handleOpenEditDate = (row) => {
    console.log(row);
    setEditData(row);
    setOpenEditDate(true);
  };

  const handleCloseEditDate = () => {
    setOpenEditDate(false);
  };

  const deleteHandle=(id)=>{
    console.log(id);
    modalSwal
      .fire({
        icon: "warning",
        title: "คุณต้องการลบข้อมูลนี้หรือไม่?",
        // text: "I want to delete this data!",
        confirmButtonText: "ยืนยัน",
        confirmButtonColor: "#e11d48",
        showCancelButton: true,
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          svDeleteDetailsPayment(id).then((res) => {
            if (res.status === 200) {
              setRefreshData(prev => prev + 1)
             SwalUI({
                      status: res.data.status,
                      // description: res.data.description,
                      description: "คุณได้ลบข้อมูลแล้ว",
                    });
            }else {
                  SwalUI({
                    status: res.data.status,
                    description: res.data.description,
                  });
                }
          })
        }
      });
  };

  console.log(rowsFilteredPayment);

  return (
    <section id="export-details-page">
      {/* {loading ? (
        <PulseLoader color="#3b326b" />
      ) : ( */}
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              position: 'relative',
              gap: '2rem',
              marginBottom: '1rem',
            }}
          >
            <p style={{ width: '100%', fontSize: '1.75rem', fontWeight: 500 }}>
              รายละเอียด ประวัติเช่า/ชำระเงิน
            </p>
          </div>
        </div>

        <div className="box-detail">
          <div className="box-Ar">
            <p className="number">{dataPayRent.BlockNumber}</p>
            <p className="size">{dataPayRent.box_size}m</p>
          </div>

          <figure className="img-boxr">
            <img
              src={`${uploadPath}${dataPayRent.image}`}
              alt=""
              className="img-storer"
            />
          </figure>

          <div className="box-Br">
            <p className="title">วันที่เริ่มเช่า</p>
            <p className="date-start">{dataPayRent.RentalDate}</p>
          </div>

          <div className="box-Cr">
            <p className="title">ค่ามัดจำ</p>
            <p className="deposit">
              {dataPayRent?.deposit?.toLocaleString()} ฿
            </p>
          </div>

          <div className="box-Dr">
            <p className="title">ค่าเช่า</p>
            <p className="rent">{dataPayRent?.rent?.toLocaleString()} ฿</p>
          </div>

          <div className="box-Er">
            <p className="title">ชื่อผู้เช่า</p>
            <p className="NameShop">{dataPayRent.name}</p>
          </div>

          <div className="box-Fr">
            <p className="title">ประเภทธุรกิจ</p>
            <div
              className="type-business"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <div
                style={{
                  borderRadius: '3px',
                  height: '16px',
                  width: '16px',
                  backgroundColor: `${dataPayRent.color}`,
                  marginRight: '6px',
                }}
              />
              <div className="NameShop">{dataPayRent.Business}</div>
            </div>
          </div>
        </div>

        <div
          className="box-button"
          style={{
            width: '100%',
            display: 'flex',
            // justifyContent: "space-between",
            alignItems: 'center',
            position: 'relative',
            gap: '2rem',
            marginBottom: '1rem',
          }}
        >
          <div
            className=""
            style={{
              display: 'flex',
              alignItems: 'center',
              position: 'relative',
              gap: '1rem',
            }}
          >
            <Button
              onClick={showTableHandler}
              variant="contained"
              sx={{
                background: showTable ? '#6C5DD3' : '#707070',
                width: '160px',
                textTransform: 'capitalize',
                fontSize: '16px',
              }}
            >
              ประวัติการเช่า
            </Button>

            <Button
              onClick={showFormHandler}
              variant="contained"
              sx={{
                background: showForm ? '#6C5DD3' : '#707070',
                width: '160px',
                textTransform: 'capitalize',
                fontSize: '16px',
              }}
            >
              ข้อมูลการชำระเงิน
            </Button>
          </div>

          <div
            className=""
            style={{
              display: 'flex',
              alignItems: 'center',
              position: 'relative',
              gap: '1rem',
            }}
          >
            <FormControl
              sx={{ minWidth: 150 }}
              size="small"
              style={{ backgroundColor: 'white' }}
            >
              <InputLabel id="demo-simple-select-label">ปี</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={year}
                label="ปี"
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>ทั้งหมด</em>
                </MenuItem>
                {YearSet}
              </Select>
            </FormControl>
          </div>
        </div>

        <Modal open={openEditDate} onClose={handleCloseEditDate}>
        <Box
             className="width-modalE"
             sx={{
               position: "absolute",
               top: "50%",
               left: "50%",
               transform: "translate(-50%, -50%)",
               bgcolor: "white",
               borderRadius: "10px",
               boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
               p: 4,
             }}
          >
            <HistoryRentt
            editData={editData}
            onClose={handleCloseEditDate}
            setRefreshData={setRefreshData}
            />
          </Box>
        </Modal>
        {showTable && (
          <div className="">
            <TableHistory detailPayRent={detailPayRent} />
          </div>
        )}

        {showForm && (
          <div className="container-form">
            <TablePayrent detailPayment={detailPayment} 
            handleOpenEditDate={handleOpenEditDate}
            deleteHandle={deleteHandle}
            />
          </div>
        )}
      </>
      {/* )} */}
    </section>
  );
}

export default PayRenttDetails;
