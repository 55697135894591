import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "./navbar.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faBars,
  faBarsStaggered,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { faBell, faComments } from "@fortawesome/free-regular-svg-icons";
import { Fab, Badge } from "@mui/material";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../../store/auth-slice";

const NavbarComponent = (props) => {
  const { isOnClick, setIsOnClick } = props;
  console.log("test-2", isOnClick);

  const { t } = useTranslation("sidebar");
  const dispatch = useDispatch();
  const { displayName, email, profileImage } = useSelector(
    (state) => state.auth.profile
  );
  const userRoleName = useSelector((state) => state.auth.userRoleName);

  const OnSignOutHandler = () => {
    dispatch(authActions.logout());
  };

  const handleToggleSidebar = () => {
    setIsOnClick(!isOnClick);
    props.collapseHandler();
  };

  return (
    <nav className="navbar">
      <div className="nav-body">
        <Fab
          size="small"
          color="secondary"
          className="btn-toggle-sidebar"
          onClick={handleToggleSidebar}
        >
          {props.isCollapsed && <FontAwesomeIcon icon={faBars} />}
          {!props.isCollapsed && <FontAwesomeIcon icon={faBarsStaggered} />}
        </Fab>
        
        
        <div className="blog-right">
   

          <figure className="fig-menu-icon user-profile">
            <img
              className="fig-badge btn-profile"
              src={profileImage || "/images/default-user.png"}
              onError={(e) =>
                e.target.setAttribute("src", "/images/default-user.png")
              }
            />
            <div className="card-profile">
              <figure className="fig-profile">
                <img
                  src={profileImage || "/images/default-user.png"}
                  onError={(e) =>
                    e.target.setAttribute("src", "/images/default-user.png")
                  }
                />
                <div className="details">
                  <p className="display">
                    {displayName} - <span>{userRoleName}</span>
                  </p>
                  <p className="email">{email}</p>
                </div>
              </figure>
              
              <div className="action-body">
                <a
                  className="signout "
                  title={t("signOut")}
                  onClick={OnSignOutHandler}
                >
                  <figure className="faIcon">
                    <FontAwesomeIcon icon={faArrowRightFromBracket} />
                  </figure>
                  <span className="menu-title">{t("signOut")}</span>
                </a>
              </div>
            </div>
          </figure>
        </div>

      </div>
    </nav>
  );
};

export default NavbarComponent;
