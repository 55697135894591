import React, { useState, useEffect } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import Tables from './components/Tables1';
import Tables2 from './components/Tables2';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import {
  Button,
  TextField as MUITextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import './DashboardPage.scss';
import { svDataDays, svDataMonth } from '../../services/graph';
import { getPayRentData } from '../../services/payrent.service';

function DashboardPage() {
  const [age, setAge] = useState('');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [chartWidth, setChartWidth] = useState(
    window.innerWidth >= 1600 ? 1550 : window.innerWidth >= 1025 ? 700 : 930
  );
  const [rentalStatus1, setRentalStatus1] = useState('');
  const [rentalStatus2, setRentalStatus2] = useState('');
  const [rows, setRows] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [totalReFund, setTotalReFund] = useState(0);

  //ข้อมูลกราฟ
  useEffect(() => {
    svDataMonth().then((res) => {
      const data = res.data?.data || [];
      console.log('API Response Data:', data);

      const formatDate = (dateString) => {
        if (!dateString) return null;
        const [day, month, year] = dateString.split('/').map(Number);
        const date = new Date(year, month - 1, day);
        if (isNaN(date.getTime())) return null;
        const formattedYear = date.getFullYear();
        const formattedMonth = String(date.getMonth() + 1).padStart(2, '0');
        return `${formattedYear}-${formattedMonth}`;
      };

      const expensesMap = {};

      const addExpense = (key, amount) => {
        if (!expensesMap[key]) {
          expensesMap[key] = {
            salary: 0,
            repair: 0,
            garbage: 0,
            electricity: 0,
            cleaning: 0,
            miscellaneous: 0,
            fines: 0,
            overdue: 0,
          };
        }
        expensesMap[key].salary += amount.salary || 0;
        expensesMap[key].repair += amount.repair || 0;
        expensesMap[key].garbage += amount.garbage || 0;
        expensesMap[key].electricity += amount.electricity || 0;
        expensesMap[key].cleaning += amount.cleaning || 0;
        expensesMap[key].miscellaneous += amount.miscellaneous || 0;
        expensesMap[key].fines += amount.fines || 0;
        expensesMap[key].overdue += amount.overdue || 0;
      };

      (data.expenses || []).forEach((item) => {
        const key = formatDate(item.payment_salary_date);
        if (key) addExpense(key, { salary: item.salary });
      });

      (data.repair || []).forEach((item) => {
        const key = formatDate(item.date_repair);
        if (key) addExpense(key, { repair: item.repair_expenses });
      });

      (data.garbages || []).forEach((item) => {
        const key = formatDate(item.date_garbages);
        if (key) addExpense(key, { garbage: item.garbage_expenses });
      });

      (data.electricity || []).forEach((item) => {
        const key = formatDate(item.date_electricity);
        if (key) addExpense(key, { electricity: item.electricity_expenses });
      });

      (data.cleaning || []).forEach((item) => {
        const key = formatDate(item.date_cleaning);
        if (key) addExpense(key, { cleaning: item.cleaning_expenses });
      });

      (data.miscellaneous || []).forEach((item) => {
        const key = formatDate(item.date_miscellaneous);
        if (key)
          addExpense(key, { miscellaneous: item.miscellaneous_expenses });
      });

      (data.expiration || []).forEach((item) => {
        const key = formatDate(item.date_fines);
        if (key)
          addExpense(key, {
            fines: item.fines_expenses,
            overdue: item.overdue_expenses,
          });
      });

      const rowsData = Object.keys(expensesMap).map((key) => {
        const {
          salary,
          repair,
          garbage,
          electricity,
          cleaning,
          miscellaneous,
          fines,
          overdue,
        } = expensesMap[key];
        const total =
          salary + repair + garbage + electricity + cleaning + miscellaneous;
        return {
          monthYear: key,
          total,
          overdue,
          fines,
        };
      });

      // แสดงข้อมูลทั้งหมดเมื่อ selectedValue ไม่มีการกรอง
      rowsData.sort((a, b) => {
        const [yearA, monthA] = a.monthYear.split('-').map(Number);
        const [yearB, monthB] = b.monthYear.split('-').map(Number);
        return yearB - yearA || monthB - monthA;
      });

      setRows(rowsData.slice(0, 6));
    });

    svDataDays().then((res) => {
      const data = res.data?.data;
      console.log('API Response Data Days:', data);

      const formatDate = (dateString) => {
        if (!dateString) return null;
        const [day, month, year] = dateString.split('/').map(Number);
        const date = new Date(year, month - 1, day);
        if (isNaN(date.getTime())) return null;
        const formattedDay = String(date.getDate()).padStart(2, '0');
        const formattedMonth = String(date.getMonth() + 1).padStart(2, '0');
        const formattedYear = date.getFullYear();
        return `${formattedDay}-${formattedMonth}-${formattedYear}`;
      };

      const expensesMap = {};

      const addExpense = (key, amount) => {
        if (!expensesMap[key]) {
          expensesMap[key] = {
            rent: 0,
            tentfee: 0,
            electric: 0,
            water: 0,
          };
        }
        expensesMap[key].rent += amount.rent || 0;
        expensesMap[key].tentfee += amount.tentfee || 0;
        expensesMap[key].electric += amount.electric || 0;
        expensesMap[key].water += amount.water || 0;
      };

      (data.rent_tent || []).forEach((item) => {
        const key = formatDate(item.date_rent);
        if (key)
          addExpense(key, {
            rent: item.rent,
            tentfee: item.tentfee,
          });
      });

      (data.electric || []).forEach((item) => {
        const key = formatDate(item.date_electric);
        if (key) addExpense(key, { electric: item.electric });
      });

      (data.water || []).forEach((item) => {
        const key = formatDate(item.date_water);
        if (key) addExpense(key, { water: item.water });
      });

      const rowsData = Object.keys(expensesMap).map((key) => {
        const { rent, tentfee, electric, water } = expensesMap[key];
        const total = rent + tentfee + electric + water;
        return {
          dMy: key,
          rent,
          tentfee,
          electric,
          water,
        };
      });

      rowsData.sort((a, b) => {
        const [dayA, monthA, yearA] = a.dMy.split('-').map(Number);
        const [dayB, monthB, yearB] = b.dMy.split('-').map(Number);
        return (
          new Date(yearB, monthB - 1, dayB) - new Date(yearA, monthA - 1, dayA)
        );
      });

      setFilteredData(rowsData.slice(0, 7));
    });

    getPayRentData().then((res) => {
      console.log(res.data?.rent);
      const reFundData = res.data?.rent;
      if (res.status === 200) {
        const sunReFund = reFundData?.reduce((a, b) => {
          return a + Number(b.ReFund);
        }, 0);
        // console.log(sunReFund);
        setTotalReFund(sunReFund);
        // setPayRentData(res.data.rent);
      }
    });
  }, []);

  const handleRentalStatusChange1 = (event) => {
    setRentalStatus1(event.target.value);
  };

  const handleRentalStatusChange2 = (event) => {
    setRentalStatus2(event.target.value);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setChartWidth(
        window.innerWidth >= 1600 ? 1000 : window.innerWidth >= 1025 ? 700 : 930
      );
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // นี่คือตัวแปรที่ใช้สำหรับการค้นหา
  const [searchInput, setSearchInput] = useState('');

  // ฟังก์ชันการฟอร์แมตค่า (ตามที่คุณใช้อยู่)
  const valueFormatter = (value) => {
    // ตัวอย่างการฟอร์แมตค่า
    return value.toLocaleString();
  };

  const chartSetting = {
    width: chartWidth,
    height: 300,
  };

  const totals = filteredData.reduce(
    (accumulator, row) => {
      accumulator.totalRent += row.rent || 0;
      accumulator.totaltentDeposi += row.tentfee || 0;
      accumulator.totalWater += row.water || 0;
      accumulator.totalElectricity += row.electric || 0;
      return accumulator;
    },
    {
      totalRent: 0,
      totalWater: 0,
      totalElectricity: 0,
      totaltentDeposi: 0,
    }
  );

  const totalsMonth = rows.reduce(
    (accumulator, row) => {
      accumulator.totalFine += row.fines || 0;
      accumulator.totalExpensesPerMonth += row.total || 0;
      accumulator.totalOverdue += row.overdue || 0;
      return accumulator;
    },
    {
      totalFine: 0,
      totalExpensesPerMonth: 0,
      totalOverdue: 0,
    }
  );

  const { totalRent, totalWater, totalElectricity, totaltentDeposi } = totals;
  const { totalFine, totalExpensesPerMonth, totalOverdue } = totalsMonth;

  const formattedTotalRent = totalRent.toLocaleString();
  const formattedTotalWater = totalWater.toLocaleString();
  const formattedTotalElectricity = totalElectricity.toLocaleString();
  const formattedTotalFine = totalFine.toLocaleString();
  const formattedTotalExpensesPerMonth = totalExpensesPerMonth.toLocaleString();
  const formattedTotalOverdue = totalOverdue.toLocaleString();
  const formattedTotalTentDeposi = totaltentDeposi.toLocaleString();

  return (
    <section>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'relative',
          marginBottom: '1.5rem',
        }}
      >
        <div style={{ fontSize: '1.75rem', fontWeight: 500 }}>แดชบอร์ด</div>
        <Button
          variant="contained"
          size="small"
          style={{
            fontSize: '16px',
            background: '#707070',
            color: 'white',
            height: '39px',
          }}
          component="a"
          href="/products/add"
          className="button-h"
        >
          เพิ่มข้อมูลการเช่า
        </Button>
      </div>

      <div className="main-graphs">
        {/* ข้อมูลรายได้ */}
        <div className="income">
          <div
            className=""
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <p style={{ fontSize: '18px', fontWeight: '500' }}>
              รวมข้อมูลรายได้ทั้งหมด
            </p>
            <Button
              variant="contained"
              size="small"
              style={{
                fontSize: '16px',
                background: '#707070',
                color: 'white',
                height: '35px',
              }}
              component="a"
              href="/graphs"
              target="_blank"
              className="button-h"
            >
              ดูทั้งหมด
            </Button>
          </div>
          <div className="income-rent">
            <div className="rent">
              <h1 style={{ fontSize: '16px', fontWeight: '400' }}>ค่าเช่า :</h1>
              <p style={{ fontSize: '20px', fontWeight: '700' }}>
                {formattedTotalRent} ฿
              </p>
            </div>
            <div className="tent-deposi">
              <h1 style={{ fontSize: '16px', fontWeight: '400' }}>
                ค่ามัดจำเต็นท์ :
              </h1>
              <p style={{ fontSize: '20px', fontWeight: '700' }}>
                {formattedTotalTentDeposi} ฿
              </p>
            </div>
            <div className="water">
              <h1 style={{ fontSize: '16px', fontWeight: '400' }}>
                ค่าตู้กดน้ำ :
              </h1>
              <p style={{ fontSize: '20px', fontWeight: '700' }}>
                {formattedTotalWater} ฿
              </p>
            </div>
            <div className="fiElectricityne">
              <h1 style={{ fontSize: '16px', fontWeight: '400' }}>ค่าไฟ :</h1>
              <p style={{ fontSize: '20px', fontWeight: '700' }}>
                {formattedTotalElectricity} ฿
              </p>
            </div>
            <div className="Fine">
              <h1 style={{ fontSize: '16px', fontWeight: '400' }}>ค่าปรับ :</h1>
              <p style={{ fontSize: '20px', fontWeight: '700' }}>
                {formattedTotalFine} ฿
              </p>
            </div>
            <div className="ExpensesPerMonth">
              <h1 style={{ fontSize: '16px', fontWeight: '400' }}>
                ค่าใช้จ่ายรายเดือน :
              </h1>
              <p style={{ fontSize: '20px', fontWeight: '700' }}>
                {formattedTotalExpensesPerMonth} ฿
              </p>
            </div>
            <div className="Overdue">
              <h1 style={{ fontSize: '16px', fontWeight: '400' }}>
                ค่าเช่าค้างจ่าย :
              </h1>
              <p style={{ fontSize: '20px', fontWeight: '700' }}>
                {formattedTotalOverdue} ฿
              </p>
            </div>
            <div className="Refund">
              <h1 style={{ fontSize: '16px', fontWeight: '400' }}>ยอดคืนเงิน</h1>
              <p style={{ fontSize: '20px', fontWeight: '700' }}>
                {totalReFund.toLocaleString()} ฿
              </p>
            </div>
          </div>
        </div>

        <div className="total-income">
          {/* กราฟ 1 */}
          <div className="graphs-total">
            <div
              className=""
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <p style={{ fontSize: '18px', fontWeight: '500' }}>
                ข้อมูลรายได้ รวม
              </p>
              <p
                style={{
                  fontSize: '14px',
                  fontWeight: '400',
                  color: '#707070',
                }}
              >
                7 วันย้อนหลัง
              </p>
            </div>
            <div className="barchart">
              <div style={{ overflow: 'auto', width: '100%' }}>
                {filteredData.length > 0 ? (
                  <BarChart
                    dataset={filteredData}
                    xAxis={[{ scaleType: 'band', dataKey: 'dMy' }]}
                    series={[
                      { dataKey: 'rent', label: 'ค่าเช่า', color: '#6C5DD3' },
                      {
                        dataKey: 'tentfee',
                        label: 'ค่ามัดจำเต็นท์',
                        color: '#e695d4',
                      },
                      {
                        dataKey: 'water',
                        label: 'ค่าตู้กดน้ำ',
                        color: '#A0D7E8',
                      },
                      {
                        dataKey: 'electric',
                        label: 'ค่าไฟ',
                        color: '#FFCE72',
                      },
                    ]}
                    valueFormatter={valueFormatter}
                    {...chartSetting}
                  />
                ) : (
                  <p>ไม่มีข้อมูล...</p>
                )}
              </div>

              <div className="main-color">
                <figure className="flex-box-1">
                  <div className="color-1"></div>
                  <p>ค่าเช่า</p>
                </figure>
                <figure className="flex-box-7">
                  <div className="color-7"></div>
                  <p>ค่ามัดจำเต็นท์</p>
                </figure>
                <figure className="flex-box-2">
                  <div className="color-2"></div>
                  <p>ค่าตู้กดน้ำ</p>
                </figure>
                <figure className="flex-box-3">
                  <div className="color-3"></div>
                  <p>ค่าไฟ</p>
                </figure>
              </div>
            </div>
          </div>

          {/* กราฟ 2*/}
          <div className="graphs-total">
            <div
              className=""
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <p style={{ fontSize: '18px', fontWeight: '500' }}>
                ข้อมูลรายได้ รวม
              </p>
              <p
                style={{
                  fontSize: '14px',
                  fontWeight: '400',
                  color: '#707070',
                }}
              >
                6 เดือนย้อนหลัง
              </p>
            </div>
            <div className="barchart">
              <div style={{ overflow: 'auto', width: '100%' }}>
                {rows.length > 0 ? (
                  <BarChart
                    dataset={rows} // ใช้ rows ตรงนี้
                    xAxis={[{ scaleType: 'band', dataKey: 'monthYear' }]}
                    series={[
                      { dataKey: 'fines', label: 'ค่าปรับ', color: '#898088' },
                      {
                        dataKey: 'total',
                        label: 'ค่าใช้จ่ายรายเดือน',
                        color: '#6edcff',
                      },
                      {
                        dataKey: 'overdue',
                        label: 'ค่าเช่าค้างจ่าย',
                        color: '#FF8D8D',
                      },
                    ]}
                    valueFormatter={valueFormatter}
                    {...chartSetting}
                  />
                ) : (
                  <p>ไม่มีข้อมูล...</p>
                )}
              </div>

              <div className="main-color">
                {/* <figure className="flex-box-1">
                <div className="color-1"></div>
                <p>ค่าเช่า</p>
              </figure>
              <figure className="flex-box-7">
                <div className="color-7"></div>
                <p>ค่ามัดจำเต็นท์</p>
              </figure>
              <figure className="flex-box-2">
                <div className="color-2"></div>
                <p>ค่าตู้กดน้ำ</p>
              </figure>
              <figure className="flex-box-3">
                <div className="color-3"></div>
                <p>ค่าไฟ</p>
              </figure> */}
                <figure className="flex-box-4">
                  <div className="color-4"></div>
                  <p>ค่าปรับ</p>
                </figure>
                <figure className="flex-box-5">
                  <div className="color-5"></div>
                  <p>ค่าใช้จ่ายรายเดือน</p>
                </figure>
                <figure className="flex-box-6">
                  <div className="color-6"></div>
                  <p>ค่าเช่าค้างจ่าย</p>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ตัวเลือกการกรองตามสถานะการเช่า */}
      <div className="dropdow">
        <div className="" style={{ fontSize: '18px', fontWeight: '500' }}>
          รายงานสถานะการเช่า
        </div>
        <div className="">
          <FilterAltOutlinedIcon sx={{ fontSize: 30, color: '#707070' }} />
          <FormControl fullWidth size="small" style={{ width: '165px' }}>
            <InputLabel id="demo-select-small-label">สถานะการเช่า</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={rentalStatus1}
              label="สถานะการเช่า"
              onChange={handleRentalStatusChange1}
            >
              <MenuItem value="">
                <em>ทั้งหมด</em>
              </MenuItem>
              <MenuItem value={'0'}>ว่าง</MenuItem>
              <MenuItem value={'1'}>ยังเช่าอยู่</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>

      {/* ตารางส่วนบน */}
      <div style={{ height: '326px' }}>
        <Tables searchInput={searchInput} rentalStatus={rentalStatus1} />{' '}
        {/* ส่งค่าสถานะการเช่าไปยังคอมโพเนนต์ของตาราง */}
      </div>

      {/* ตารางส่วนล่าง */}
      <div style={{ marginTop: '1.5rem' }} className="dropdow">
        <div className="" style={{ fontSize: '18px', fontWeight: '500' }}>
          รายงานรายได้ UFO Market
        </div>
        <div className="">
          <FilterAltOutlinedIcon sx={{ fontSize: 30, color: '#707070' }} />
          <FormControl fullWidth size="small" style={{ width: '165px' }}>
            <InputLabel id="demo-select-small-label">สถานะการเช่า</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={rentalStatus2}
              label="สถานะการเช่า"
              onChange={handleRentalStatusChange2}
            >
              <MenuItem value="">
                <em>ทั้งหมด</em>
              </MenuItem>
              <MenuItem value={'0'}>ว่าง</MenuItem>
              <MenuItem value={'1'}>ยังเช่าอยู่</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>

      <div style={{ height: '326px' }}>
        <Tables2 searchInput={searchInput} rentalStatus={rentalStatus2} />{' '}
        {/* ส่งค่าสถานะการเช่าไปยังคอมโพเนนต์ของตาราง */}
      </div>
    </section>
  );
}

export default DashboardPage;
